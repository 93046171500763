import React from 'react';
import WaistManual from "../../components/WaistManual";
import useWaistKRImages from "../../hooks/useWaistKRImages";

const locale = "kr";
export default () => {
	const images = useWaistKRImages();
	return (
		<>
			<WaistManual locale={locale} images={images}/>
		</>
	);
}
